import { IEnvironment } from '@env/interfaces/environment.interface';

export const environment: IEnvironment = {
  production: true,
  awsRegion: 'us-west-2',
  awsUserPoolId: 'us-west-2_0yNMdRLRz',
  awsUserPoolWebClientId: '6ea4bsd3lnpm4565ts8sa913gj',
  awsUserPoolAdminGroupName: 'Admin',
  hayhouseBaseUrl: 'https://api.services.hayhouse.com/api/v2',
  backendEnvironment: 'production',
  klevuTicketId: 'klevu-158343112147610639',
  klevuBaseUrl: 'https://eucs18.ksearchnet.com/cloud-search/n-search/search'
};
